<template id="rezervari">
    <div class="rezervari">
        <div class="content">

            <div class="left-content">
                <div class="header">
                    <div v-for="page in pages" :key="page.page_number" :class="page.completed ? 'steps-active' : 'steps-inactive'"></div>
                </div>
                
                
                <div class="main">                     
                    <img :src="getImgUrl(pages[current_page].src)">
                    <h1>{{pages[current_page].title}}</h1>
                    <h3>{{pages[current_page].description}}</h3>
                </div>
                <div class="footer">
                    <h1>Intrebari?</h1>
                    <h3>Suna la 0754 00 44 00</h3>
                </div>
            </div>
            <!-- alege ora -->
            <div class="center-content" v-if="current_page === 1">
                
                <div class="header">
                    <h1>Alege Data si Ora</h1>
                </div>
                <div class="main-ora">
                    <div class="nav">
                        <el-button @click="previous_month()" icon="el-icon-arrow-left"></el-button>
                       <p>{{luna_selectata.nume}} <span>{{an_selectat}}</span></p>
                       <el-button @click="next_month()" icon="el-icon-arrow-right"></el-button>
                    </div>
                    <div class="container-program">
                        <div class="program" v-loading="loader">
                            <div class="header">LUN</div>
                            <div class="header">MAR</div>
                            <div class="header">MIE</div>
                            <div class="header">JOI</div>
                            <div class="header">VIN</div>
                            <div class="header header-weekend">SAM</div>
                            <div class="header header-weekend">DUM</div>
                        <div v-for="(value, index) of Program" :key="index+value" class="zi" :style="(value[1].ziua_saptamanii == '0' || value[1].ziua_saptamanii == '6') ? 'background-color: #FCFEF1' : ''">
                            <div @click="select_date(value)"
                            :class="value[1].count_intervale_libere == 0 && value[1].count_intervale_libere == value[1].count_intervale_ocupate ? 'button-zi-indisponibila' : 
                                    value[1].count_intervale_libere > 0 ? value[1].luna_curenta == 1 ? 'button-zi-disponibila' : 'button-zi-disponibila_nu_in_luna_curenta' : 'button-zi-indisponibila'"
                            >{{get_day(value[0])}}
                                <div v-if="value[1].count_intervale_libere > 0 && value[1].intervale" class="bara-liber">
                                    <div v-for="(v, i) of value[1].intervale" :key="'interval' + i" :class="v.liber ? 'liber' : 'ocupat'"></div>
                                </div>
                            </div>
                        </div>


                        </div>
                            <div id="ore" class="ore" v-if="selectedObject.data != undefined">
                                <p><span class="bara">Alege ora programarii pentru <span class="data">{{selectedObject.data}}</span></span></p>
                                <div class="butoane">
                                    <div  v-for="(value, index) of selectedObject.data_object.intervale" :key="'ora' + index" :class="value.liber ? 'liber' : 'ocupat'" @click="select_hour(value)">
                                        {{index}}
                                    </div>
                                </div>
                            </div>
                    </div>  
                </div>
                <div class="footer-ora">
                    <!-- <div class="content-footer">
                       <a @click="previous_page()"><i class="el-icon-back"></i> Inapoi</a>
                    </div> -->
                </div>
            </div>

            <div class="center-content" v-if="current_page === 2">
                <div class="header">
                    <h1>Date de contact</h1>
                </div>
                <div class="main-formular" v-loading="loader">

                    <div class="formular">
                        <v-form
                        @submit.native.prevent="save()"
                        lasy-validation
                        class="formular"
                        label-position="top"
                        :inline="false"
                        label-width="100%"
                        :model="selectedObject.Data"
                        :rules="rules"
                        :ref="'my-form'"
                    >
                        <el-row class="rand">
                        <el-col class="coloana">
                            <!-- <el-input v-model="Data.Nume" placeholder="Introdu numele"></el-input> -->
                            <v-text-field
                            dense
                            disabled
                            label="Nume"
                            v-model="selectedObject.Data.Nume"
                            outlined
                            :rules="[rules_vuetify.required]"
                            ></v-text-field>
                        </el-col>
                        <el-col class="coloana">
                            <v-text-field
                            dense
                            disabled
                            label="Prenume"
                            v-model="selectedObject.Data.Prenume"
                            outlined
                            :rules="[rules_vuetify.required]"
                            ></v-text-field>
                        </el-col>
                        </el-row>
                        <el-row class="rand">
                        <el-col class="coloana">
                            <v-text-field
                            dense
                            disabled
                            label="Telefon"
                            v-model="selectedObject.Data.Telefon"
                            outlined
                            oninvalid="this.setCustomValidity('Completeaza acest camp')"
                            :rules="[rules_vuetify.required]"
                            ></v-text-field>
                        </el-col>
                        <el-col class="coloana">
                            <v-text-field
                            dense
                            disabled
                            label="Email"
                            v-model="selectedObject.Data.Email"
                            outlined
                            oninvalid="this.setCustomValidity('Completeaza acest camp')"
                            :rules="[rules_vuetify.required]"
                            ></v-text-field>
                        </el-col>
                        </el-row>
                        <el-row class="rand">
                        <el-col class="coloana">
                            <v-text-field
                            dense
                            disabled
                            label="Numar masina"
                            v-model="selectedObject.Data.Numar"
                            hint="Ex: B123ABC"
                            outlined
                            :rules="[rules_vuetify.required]"
                            ></v-text-field>
                        </el-col>
                        <el-col class="coloana">
                            <v-text-field
                            dense
                            disabled
                            label="Numar kilometri"
                            v-model="selectedObject.Data.Km"
                            outlined
                            ></v-text-field>
                        </el-col>
                        </el-row>
                        <el-row class="rand">
                        <el-col class="coloana">
                            <v-text-field
                            dense
                            disabled
                            label="Marca"
                            v-model="selectedObject.Data.Marca"
                            hint="Ex: Dacia"
                            outlined
                            ></v-text-field>
                        </el-col>
                        <el-col class="coloana">
                            <v-text-field
                            dense
                            disabled
                            label="Model"
                            v-model="selectedObject.Data.Model"
                            hint="Ex: Logan"
                            outlined
                            ></v-text-field>
                        </el-col>
                        </el-row>
                        <el-row class="rand">
                        <el-col class="an">
                            <el-date-picker
                            disabled
                                value-format="yyyy"
                                type="year"
                                :picker-options="datePickerOptions"
                                placeholder="Alegeti anul fabricatiei"
                                v-model="selectedObject.Data.An"
                                style="margin-bottom: 10px"
                            ></el-date-picker>
                        </el-col>
                        </el-row>

                        </v-form>
                    </div>
                </div>
                <div class="footer-formular">
                    <a @click="previous_page()"><i class="el-icon-back"></i> Inapoi</a>
                    <el-button @click="save()">
                        <template>
                            Finalizeaza programarea <i class="el-icon-right"></i>
                        </template>
                    </el-button>
                </div>
            </div>
            <div class="right-content">
                <div class="header">
                    <h1><span>Sumar</span></h1>
                </div>
                <div class="main">
                    <div class="element" v-for="s of sumar" :key="s.titlu + '_sumar'">
                        <h3>{{s.titlu}}</h3>
                        <h1>{{s.descriere}}</h1>
                    </div>
                </div>
            </div>

        </div>
        <Finalizare ref="finalizare" @close='reload()' @closed="reload()"/>
    </div>
</template>

<script>
import moment from 'moment';
import Finalizare from './Finalizare.vue';
import settings from "../backend/LocalSettings";

    export default {
        
        name: "Amanare",
        components: {
            Finalizare
        },
        data() {
            return {
                SecureId: null,
                EmailClient: '',
                datePickerOptions: {
                    disabledDate(date) {
                    return date > new Date();
                    },
                },
                current_page: 1,
                loader: false,
                pages: [
                    {
                        completed: true,
                        page_number: 0,
                        title: 'Programare Service',
                        description: "",
                        src: "page1.png"
                    },
                    {
                        completed: false,
                        page_number: 1,
                        title: 'Alege Data si Ora',
                        description: "",
                        src: "page2.png"
                    },
                    {
                        completed: false,
                        page_number: 2,
                        title: 'Lasa-ne datele tale de contact',
                        description: "",
                        src: "page3.png"
                    },
                ],
                servicii: [],
                sumar: [
                    /**
                     * TEMPLATE SUMAR
                     {
                        titlu: 'titlu',
                        descriere: 'descriere'
                     }
                     */
                ],
                serviciu_selectat: {
                    centru: null,
                    id: null,
                    nume: null,
                    pret: null
                },
                tip_cont: {
                    client_nou: true,
                    client_vechi: false,
                },
                Program: {},
                Marci: [],
                Roti: {
                    Hotel: false,
                    Cantitate: 1,
                },
                Model_marci: [],
                selectedObject: {
                    data: undefined,
                    data_object: undefined,
                    serviciu: '',
                    Data: {
                        Nume: '',
                        Prenume: '',
                        Telefon: '',
                        Email: '',
                        Numar: '',
                        Km: '',
                        Marca: '',
                        Model: '',
                        An: '',
                        Ora: undefined,
                        Ora_start: undefined,
                        Ora_stop: undefined,
                    },
                },
                luna_selectata: {
                    numar: '',
                    nume: ''
                },
                an_selectat: "",
                rules_vuetify: {
                    required: (valid) => !!valid || "Camp obligatoriu",
                },
                rules: {
                    Nume: [{ required: true, message: "Campul este obligatoriu" }],
                    Prenume: [{ required: true, message: "Campul este obligatoriu" }],
                    Numar: [{ required: true, message: "Campul este obligatoriu" }],
                    Telefon: [{ required: true, message: "Campul este obligatoriu" }],
                    Ora: [{ required: true, message: "Campul este obligatoriu" }],
                },
            }
        },
        methods: {

            reload: function() {
                this.$router.go(0);
            },

            alege_serviciu: async function(serviciu) {
                this.serviciu_selectat = {
                    centru: serviciu.IdCentruProductie,
                    id: serviciu.Id,
                    nume: serviciu.Nume,
                    pret: serviciu.Pret
                };
                this.sumar[0] = {
                    titlu: 'SERVICIU',
                    descriere: serviciu.Nume
                }
                this.load_program(serviciu.IdCentruProductie);
                this.next_page();
            },


            test_save: async function() {
                this.$refs.finalizare.show_me(this.serviciu_selectat, this.selectedObject.Data, 1234);
            },

            save: async function() {
                let valid = this.$refs["my-form"].validate()
                if (valid) {
                this.selectedObject.Data.Cantitate = this.Roti.Cantitate;
                this.selectedObject.Data.Hotel = this.Roti.Hotel;
                let args = { data: this.selectedObject.Data, idServiciuProgramator: this.serviciu_selectat.id, SecureId: this.SecureId , CheckSender:settings.get_key()}
                let api_url =
                    "https://service.anvelopeinstoc.ro/api/index.php/test_api/modifica_programare";
                let res = await this.$http.post(api_url, args);
                if (res.body.success == true) {
                    let idProgramare = res.body.IdProgramare;
                    this.$refs.finalizare.show_me(this.serviciu_selectat, this.selectedObject.Data, idProgramare);
                    await this.$message({
                    type: "success",
                    message: "Programare amanata cu succes",
                    });
                } else {
                    this.$message({
                    type: "warning",
                    message: "Programarea nu a putut fi amanata. (" + res.body.error + ')',
                    });
                }
                }
            },

            select_date: async function(value) {
                if (value[1].count_intervale_libere == 0) return;
                if (value[1].count_intervale_libere == value[1].count_intervale_ocupate && value[1].count_intervale_libere == 0) return;
                let cantitate = this.Roti.Cantitate;
                let hotel = this.Roti.Hotel
                let api_url =
                    "https://service.anvelopeinstoc.ro/api/index.php/test_api/index_zi";
                let args = {zi: value[0], idServiciuProgramator: this.serviciu_selectat.id, Cantitate: cantitate, Hotel: hotel, CheckSender:settings.get_key()}
                let response = await this.$http.post(api_url, args);
                this.selectedObject.data = `${this.get_day(value[0])} ${this.get_nume_luna(moment(value[0]).format('MM'))}`;
                this.selectedObject.data_object = response.data;
                this.sumar[1] = {
                    titlu: 'DATA',
                    descriere: this.selectedObject.data
                };
                setTimeout(() => {
                    document.getElementById('ore').scrollIntoView();
                }, 10)
            },

            select_hour: function(value) {
                if (!value.liber) return;
                this.selectedObject.Data.Ora = value.start;
                this.selectedObject.Data.Ora_start = value.start;
                this.selectedObject.Data.Ora_stop = value.stop;
                this.sumar[2] = {
                    titlu: 'ORA',
                    descriere: moment(value.start).format('HH:mm'),
                }
                this.next_page();
            },

            next_page: async function() {
                this.pages[this.current_page].completed = true;
                this.current_page++;
            },

            previous_page: async function() {
                this.current_page--;
                this.pages[this.current_page].completed = false;
            },

            getImgUrl(page) {
                return require("../assets/"+page)
            },

            get_day: function(date) {
                let day = moment(date).format('D');
                return day;
            },

            clear_program: function() {
                this.Program = {};
            },

            previous_month: async function() {
                this.loader = true;
                let previous_month = moment(this.luna_selectata.numar).add(-1, 'M').startOf('month').format('YYYY-MM-DD hh:mm:ss');
                let hotel = this.Roti.Hotel;
                let cantitate = this.Roti.Cantitate;

                let args = {
                    start: previous_month,
                    Hotel: hotel,
                    Cantitate: cantitate,
                    idServiciuProgramator: this.serviciu_selectat.id, 
                    date_client: JSON.stringify({"Email": this.EmailClient}),
                    CheckSender:settings.get_key()
                }
                let url = 'https://service.anvelopeinstoc.ro/api/index.php/test_api/index_php';
                let response = await this.$http.post(url, args);

                this.clear_program();

                this.Program = Object.entries(response.data.zile);
                this.luna_selectata.numar = moment(previous_month).format('YYYY-MM-DD hh:mm:ss');
                this.luna_selectata.nume = this.get_nume_luna(moment(previous_month).format( 'MM'));
                this.an_selectat = moment(previous_month).format('YYYY');
                this.loader = false;
             },

            next_month: async function() {
                this.loader = true;
                let next_month = moment(this.luna_selectata.numar).add(1, 'M').startOf('month').format('YYYY-MM-DD hh:mm:ss');
                let hotel = this.Roti.Hotel;
                let cantitate = this.Roti.Cantitate;

                let args = {
                    start: next_month,
                    Hotel: hotel,
                    Cantitate: cantitate,
                    idServiciuProgramator: this.serviciu_selectat.id, 
                    date_client: JSON.stringify({"Email": this.EmailClient}),
                    CheckSender:settings.get_key()
                }
                let url = 'https://service.anvelopeinstoc.ro/api/index.php/test_api/index_php';
                let response = await this.$http.post(url, args);

                this.clear_program();

                this.Program = Object.entries(response.data.zile);
                this.luna_selectata.numar = moment(next_month).format('YYYY-MM-DD hh:mm:ss');
                this.luna_selectata.nume = this.get_nume_luna(moment(next_month).format('MM'));
                this.an_selectat = moment(next_month).format('YYYY');
                this.loader = false;

            },

            get_nume_luna: function(luna) {
                var nume = '';
                      switch (luna) {
                        case "01":
                        nume = "Ianuarie";
                        break;

                        case "02":
                        nume = "Februarie";
                        break;

                        case "03":
                        nume = "Martie";
                        break;

                        case "04":
                        nume = "Aprilie";
                        break;

                        case "05":
                        nume = "Mai";
                        break;

                        case "06":
                        nume = "Iunie";
                        break;

                        case "07":
                        nume = "Iulie";
                        break;

                        case "08":
                        nume = "August";
                        break;

                        case "09":
                        nume = "Septembrie";
                        break;

                        case "10":
                        nume = "Octombrie";
                        break;

                        case "11":
                        nume = "Noiembrie";
                        break;

                        case "12":
                        nume = "Decembrie";
                        break;
                    }
                    return nume;   
            },

            load_program: async function(id) {
                this.loader = true;
                this.selectedObject = {
                    data: undefined,
                    data_object: undefined,
                    serviciu: '',
                    
                    Data: {
                        Nume: '',
                        Prenume: '',
                        Telefon: '',
                        Email: '',
                        Km: '',
                        Numar: '',
                        Marca: '',
                        Model: '',
                        An: '',
                        Ora: undefined
                    }
                }
                let url = 'https://service.anvelopeinstoc.ro/api/index.php/test_api/index_php';
                let hotel = this.Roti.Hotel;
                let cantitate = this.Roti.Cantitate;
                let args = {idServiciuProgramator: id, 
                            Hotel: hotel, 
                            Cantitate: cantitate, 
                            date_client: JSON.stringify({"Email": this.EmailClient}),
                            CheckSender:settings.get_key()}
                let response = await this.$http.post(url, args);
                this.Program = Object.entries(response.data.zile);
                this.luna_selectata.numar = moment().format('YYYY-MM-DD hh:mm:ss');
                this.luna_selectata.nume = this.get_nume_luna(moment().format('MM'));
                this.an_selectat = moment().format('YYYY'); 
                this.loader = false;
            },

            load_marci: async function() {
                let url = 'https://service.anvelopeinstoc.ro/api/index.php/test_api/get_marci';
                let args = {CheckSender:settings.get_key()}
                let response = await this.$http.post(url, args);
                this.Marci = response.body.raspuns;

            },

            load_model_marca: async function(Marca) {
                let url = 'https://service.anvelopeinstoc.ro/api/index.php/test_api/get_tipuri_marca';
                let args = {IdMarca: Marca.Id, CheckSender:settings.get_key()};
                let response = await this.$http.post(url, args);
                this.Model_marci = response.body.raspuns;
            },


            verify_client: async function(Id) {
                // let reload = this.reload();
                let args = {IdClient: Id, CheckSender:settings.get_key()}
                let url = 'https://service.anvelopeinstoc.ro/api/index.php/test_api/get_client_data';
                let response = await this.$http.post(url, args);
                // console.log(response);

                if (response.body.error) {
                    this.$message.error({message: 'Aceasta programare nu exista!', onClose: () => {
                        this.$router.push('/')
                    }});
                } else {
                    this.pages[0].title = response.body.Setari.TitleProgramator1;
                    this.pages[1].title = response.body.Setari.TitleProgramator2;
                    this.pages[2].title = response.body.Setari.TitleProgramator3;

                    this.pages[0].description = response.body.Setari.SubtitleProgramator1;
                    this.pages[1].description = response.body.Setari.SubtitleProgramator2;
                    this.pages[2].description = response.body.Setari.SubtitleProgramator3;

                    
                    let hotel = response.body.Client.Hotel == "0" ? false : true;
                    this.Roti.Hotel = hotel;
                    this.Roti.Cantitate = response.body.Client.Cantitate;
                    this.SecureId = Id;
                    this.EmailClient = response.data.Client.Email;
                    this.serviciu_selectat = {
                        id: response.body.Client.IdServiciuProgramator,
                        nume: response.body.Client.NumeServiciu,
                        pret: response.body.Client.PretServiciu,
                        centru: response.body.Client.IdCentruProductie,
                    }
                    // console.log(this.serviciu_selectat);
                    this.sumar[0] = {
                        titlu: 'SERVICIU',
                        descriere: this.serviciu_selectat.nume
                    }

                    await this.load_program(this.serviciu_selectat.id);
                    this.selectedObject.Data = {
                        Nume: response.data.Client.Nume,
                        Prenume: response.data.Client.Prenume,
                        Telefon: response.data.Client.Telefon,
                        Email: response.data.Client.Email,
                        Km: response.data.Client.Km,
                        Numar: response.data.Client.Numar,
                        Marca: response.data.Client.Marca,
                        Model: response.data.Client.Model,
                        An: response.data.Client.An,
                        Ora: undefined
                    }
                }
            },
        },
        

        mounted() {
            // console.log(this.$route.params.id);
            let Id = this.$route.params.id;
            this.verify_client(Id);
        }
    }
</script>

<style lang="less">
    @gri: #F1F2F2;
    @gri_inchis: #8e97b3;
    @albastru_transparent:rgba(76, 100, 232, 0.445);
    @albastru: #4C63E8;
    @verde: #4C63E8;
    @rosu-hover: #F7E8E8;
    @rosu: #ec7376;
    @rosu_deschis: #F7E8E8;
    @galben: #FEF6CB;
    @galben_wallpaper: #FCFEF1;
    @galben_inchis: #E1D588;
    @liber: #caffc4;
    @liber_text: #185210;
    @ocupat: #f3f5f9;
    @ocupat_text: #b3bbca;
    @zi_indisponibila: #f7e9e9;
    @zi_indisponibila_text: #752f2f;

    

    .el-dialog__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;

    }
    .el-dialog {
        margin-top: 0 !important;
        height: 300px;
    }


    .roti-container  {
        width: 100%;
        height: 100%;
        .titlu-roti {
            width: 100%;
            // border: 1px solid red;
            height: 50px;
            display: flex;
            padding: 20px;
            padding-bottom: 0;
            justify-content: flex-start;
            align-items: center;
            font-size: 14px;
            margin: 0;
            font-weight: 700;
            p {
                color: @albastru;
                padding: 0;
                margin: 0;
            }
        }
        .continut-roti {
            width: 100%;
            // height: 100%;
            padding: 0 20px;
            width: 100%;
            height: 210px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .el-form {
                display: flex;
                justify-content: space-evenly;
                // align-items: center;
                flex-direction: column;
            }
            .footer-roti {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .el-button {
                    margin: 20px 0 0;
                    background-color: @albastru;
                    color: white;
            }
            }
        }

    }

    .error--text {
        color: #ff5252 !important;
        caret-color: #ff5252 !important;
    }

    .v-application .error--text {
        color: #ff5252 !important;
        caret-color: #ff5252 !important;
    }

    

    .rezervari {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }


    .content {
        width: 60%;
        height: 63%;
        display: flex;
        align-items: center;
        flex-direction: row;
        border-radius: 5px;
        box-shadow: 0px 2px 4px #00000033, 0px 20px 50px #00000066;
    }





    .left-content, .right-content, .center-content {
        height: 100%;
    }

    .left-content {
        background-color: #fff;
        width: 22%;
        .header {
            width: 100%;
            height: 10%;
            display: flex;
            justify-content: center;
            align-items: center;

            .steps-active, .steps-inactive {
                margin: 0 0.5rem;
            }
            .steps-active {
                width: 10px;
                height: 10px;
                background-color: @albastru;
                border-radius: 2px;
            }

            .steps-inactive {
                width: 10px;
                height: 10px;
                background-color: @gri;
                border-radius: 2px;
            }
        }

        .main {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 70%;
            flex-direction: column;
            font-weight: 800;
            animation: fadeIn 1s;
            h1 {
                font-size: 18px;
                text-align: center;
                color: @albastru;
            }

            h3 {
                font-size: 14px;
                color: @gri_inchis;
            }
        }

        .footer {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 20%;
            flex-direction: column;
            font-weight: 800;

            h1 {
                font-size: 14px;
                color: @albastru;
            }

            h3 {
                font-size: 12px;
                color: @gri_inchis;
            }
        }
    }
    .center-content {
        border: 1px solid @gri;
        border-top: none;
        border-bottom: none;
        width: 50%;
        animation: fadeIn 1s;
        .header {
            width: 90%;
            height: 10%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            h1 {
                font-size: 18px;
                color: @albastru;
                margin-bottom: 0;
            }
        }

        .main {
            width: 100%;
            height: 80%;
            border-top: 1px solid @gri;
            border-bottom: 1px solid @gri;
            display: flex;
            overflow: auto;
            align-items: center;
            flex-direction: column;



            .select-container {
                
                border: 2px solid #E5E7EE !important;
                border-radius: 5px;
                width: 90%;
                margin: 20px 0;
                height: 130px;
                cursor: pointer;
                box-shadow: none;
                transition: all .3s ease;
                display: flex;
                justify-content: space-between;
                align-items: center;
                animation: FromTop 0.45s;
                .img-div {
                    width: 100px;
                    height: 100px;
                    margin: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center ;
                    img {
                        max-width:100%;
                        max-height:100%;
                    }
                }
                .serviciu {
                    transition: 0.4s;
                    width: 80%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-direction: column;
                    text-align: start;
                    padding: 0 10px;
                    h1 {
                        font-size: 18px;
                        color: @albastru

                    }
                    h4 {
                        font-size: 14px;
                    }
                }
                .pret {
                    width: 30%;
                    height: 85%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    border-left: 1px solid @gri;
                    padding: 0 10px;
                    text-align: center;
                    h1 {
                        color: @albastru;
                        font-size: 20px;
                    }

                    h4 {
                        color: @gri_inchis;
                        font-size: 14px;
                    }
                }

            }
            
            .select-container:hover {
                border: 2px solid #4c63e8 !important;
                .serviciu {
                    margin-left: 10px;
                }

            }

            .select-container-active {
                border: 3px solid @albastru !important;
                box-shadow: 5px 10px 0 @albastru_transparent;

            }

            
        }

        .footer {
            width: 100%;
            height: 10%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .el-button {
                margin: 0 20px;
                background-color: @albastru;
                color: white;
            }
        }

        .footer-ora {
            margin: 0 10px;
            width: 100%;
            height: 10%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .content-footer {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                padding: 0;
                a {
                color: @albastru;
                font-weight: 600;
                margin: 0;
                padding: 0;
                text-align: center;
                cursor: pointer;
            }
            }
        }
        
        .main-ora {
            box-sizing: border-box;
            width: 100%;
            height: 80%;
            overflow: auto;
            border-top: 1px solid @gri;
            border-bottom: 1px solid @gri;
            display: flex;
            align-items: center;
            flex-direction: column;
            scroll-behavior: smooth !important;

            
            .nav {
                width: 90%;
                height: 60px;
                margin: 20px 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                p {
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 0;

                    span {
                        color: @gri_inchis;
                    }
                }
                .el-button {
                    border: none;
                    font-weight: 800;
                }
            }

                .container-program {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                }
            

                .ore {
                    box-sizing: border-box;
                    margin: 5% 0;
                    width: 100%;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    p {
                        font-size: 16px;
                        width: 100%; 
                        text-align: center; 
                        border-bottom: 1px solid @gri; 
                        // line-height: 0.1em;
                        margin: 10px 0 20px; 
                    }
                    p .bara { 
                            background:#fff; 
                            padding:0 10px; 
                    }

                    p .data {
                        color: @albastru;
                        font-weight: 600;
                    }

                    .butoane {
                        font-size: 14px;
                        margin: 0 44px;
                        max-width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        flex-wrap: wrap;

                        .liber {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: @liber;
                            color: @liber_text;
                            margin: 3px;
                            padding: 8px 3px;
                            width: 83px;
                            height: 31px;
                            border-radius: 5px;
                        }

                        .ocupat {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: @ocupat;
                            color: @ocupat_text;
                            margin: 3px;
                            padding: 8px 3px;
                            width: 83px;
                            height: 31px;
                            border-radius: 5px;
                            cursor: not-allowed;
                        }

                        .liber:hover {
                            border: 1px solid #6bd25b;
                            cursor: pointer;
                        }

                        .button-ora {
                            margin: 3px;
                            padding: 8px 3px;
                            width: 83px;
                            height: 31px;
                            border-radius: 5px;
                        }
                    }
                }

            .program {
                    animation: ToTopOrar 0.45s;
                    margin: 0 auto;
                    padding: 0;
                    width: 100% !important;
                    box-sizing: border-box;
                    display: grid;
                    justify-content: center;
                    grid-template-columns: repeat(7, 12.5%);
                    grid-column-gap: 0px;
                    grid-row-gap: 0px;
                    .header {
                        margin: 0;
                        width: 100%;
                        height: 70px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: @albastru;
                        font-size: 14px;
                        font-weight: 700;
                    }
                    .header-weekend {
                        color: @galben_inchis;
                        background-color: @galben_wallpaper;
                    }

                    .zi {
                        margin: 0;
                        width: 100%;
                        height: 65px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                        font-size: 14px;
                        .button-zi-disponibila {
                            margin: 0;
                            width: 45px;
                            height: 45px;
                            text-align: center;
                            padding: 0;
                            border-radius: 6px;
                            background-color: @gri;
                            cursor: pointer;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            transition: 0.1s;
                            .bara-liber {
                                overflow: hidden;
                                width: 75%;
                                height: 6px;
                                border-radius: 3px;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                flex-direction: row;
                                background-color: #e6e9ef;
                                
                                .liber {
                                    width: 100%;
                                    height: 100%;
                                    margin: 0;
                                    padding: 0;
                                    background-color: #8fe286;
                                }

                                .ocupat {
                                    width: 100%;
                                    height: 100%;
                                    margin: 0;
                                    padding: 0;
                                    background-color: transparent;
                                }
                            }
                        }


                        .button-zi-disponibila:hover {
                            font-size: 18px;
                        }

                        .button-zi-disponibila_nu_in_luna_curenta {
                            opacity: 0.5;
                            margin: 0;
                            width: 45px;
                            height: 45px;
                            text-align: center;
                            padding: 0;
                            border-radius: 6px;
                            background-color: #efefefbb;
                            cursor: pointer;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            transition: 0.1s;
                            .bara-liber {
                                overflow: hidden;
                                width: 75%;
                                height: 6px;
                                border-radius: 3px;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                flex-direction: row;
                                background-color: #e6e9ef;
                                
                                .liber {
                                    width: 100%;
                                    height: 100%;
                                    margin: 0;
                                    padding: 0;
                                    background-color: #8fe286;
                                }

                                .ocupat {
                                    width: 100%;
                                    height: 100%;
                                    margin: 0;
                                    padding: 0;
                                    background-color: transparent;
                                }
                            }
                        }


                        .button-zi-disponibila_nu_in_luna_curenta:hover {
                            font-size: 18px;
                            opacity: 1;
                        }

                        .button-zi-indisponibila {
                            margin: 0;
                            width: 45px;
                            height: 45px;
                            text-align: center;
                            padding: 0;
                            border-radius: 6px;
                            background-color: @zi_indisponibila;
                            color: @zi_indisponibila_text;
                            cursor: not-allowed;
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            align-items: center;
                            opacity: 0.5;
                        }

                        .button-zi-plina {
                            margin: 0;
                            width: 55px;
                            height: 55px;
                            text-align: center;
                            padding: 0;
                            border-radius: 6px;
                            background-color: @zi_indisponibila;
                            color: @zi_indisponibila_text;
                            cursor: not-allowed;
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            align-items: center;
                        }
                    }

            }


            .weeks {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin: 0;
                padding: 10px 0;

                .el-button {
                    margin: 10px 0;
                    width: 50px;
                    height: 50px;
                    text-align: center;
                    padding: 0;
                    span {
                        font-weight: 800 !important;
                    }

                }
            }
        }

        .main-formular {
            width: 100%;
            height: 82%;
            overflow: auto;
            padding: 1% 5%;
            border-top: 1px solid @gri;
            border-bottom: 1px solid @gri;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            .titlu-formular {
                width: 100%;
               margin: 20px 0;
               display: flex;
               justify-content: space-between;
               text-align: start;
               height: 50px;
               h1 {
                font-weight: 400;
                color: @gri_inchis;
                font-size: 14px;
                width: 30%;
                height: 100%;
                cursor: pointer;
               }
            .selectat {
                border-bottom: 2px solid @albastru;
                font-size: 16px;
                animation: grow .269s;
                color: black;
                font-weight: 500;
               }
               border-bottom: 1px solid @gri_inchis;
            }

            .formular {
                width: 100%;
                margin: 15px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .rand {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                }
                .coloana {
                    width: 49%;
                    margin: 0 1%;
                }

                .an {
                    width: 49%;
                    margin: 0 1%;
                    .el-date-editor.el-input {
                        width: 100%;
                    }
                }

                .an:first-child, .coloana:first-child {
                    margin-left: 0;
                }

                .an:last-child, .coloana:last-child {
                    margin-right: 0;
                }
            }
            
        }
        
        .footer-formular {
            width: 100%;
            min-height: 7%;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            a {
                color: @albastru;
                font-weight: 600;
                margin: 5px 20px;

            }

            a:hover {
                color: @albastru;
                cursor: pointer;
            }
            .el-button {
                border: none;
                margin: 5px 20px;
                background-color: @albastru;
                color: white;
            }
            
        }

    }


    .program_plin {
        background-color: @rosu_deschis;
        border-color: @rosu_deschis;
        color: rgba(0, 0, 0, 0.311);
    }
    .right-content {
        background-color: #fff;
        width: 28%;
        .header {
            display: flex;
            height: 10%;
            justify-content: flex-start;
            padding: 0 20px;
            align-items: center;
            h1 {
                font-size: 18px;
                color: @albastru;
                width: 100%; 
                text-align: end; 
                border-bottom: 2.5px dotted @albastru;
                border-top: 2.5px dotted @albastru;
                line-height: 0.1em;
                margin: 10px 0; 
            }
            h1 span { 
                    background:#fff; 
                    padding:0 10px;
                    margin: 0 30px 
            }
        }

        .main {
            width: 100%;
            height: 80%;
            border-top: 1px solid @gri;
            border-bottom: 1px solid @gri;
            padding: 0 15px;
            display: grid;
            justify-content: center;
            grid-template-columns: repeat(2, 50%);
            grid-template-rows: repeat(3, 60px);
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            justify-content: flex-start;
            flex-wrap: wrap;
            flex-direction: column;
            .element {
                width: 100%;
                height: 60px;
                text-align: left;
                animation: ToTopOrar 0.2s;
                padding: 15px 0;
                border-bottom: 1px solid @gri;
                h3 {
                    font-size: 10px;
                    color: @gri_inchis;
                    font-weight: 600;
                    margin: 0;
                }
                h1 {
                    font-size: 14px;
                    color: @albastru;
                    font-weight: 600;
                }
            }
        }
    }

    @media only screen and (max-width: 1500px) {
        .content {
            width: 80%;
            height: 80%;
        }
    }

    @media only screen and (max-width: 780px) {
        .content {
            background-color: #fff;
            width: 100% !important;
            height: 100% !important;
            border-radius: 0px;
            box-shadow: none;
            margin: 0;
            padding: 0;
            flex-direction: column;
            justify-content: center;

        }
        .left-content, .right-content {
            display: none;
        }
        .center-content {
            width: 100% !important;
            height: 100% !important;
            overflow: hidden;
            padding: 0;
            .header {
                height: 10% !important;
            }
            .main {
                height: 80% !important;
            }
            .footer {
                height: 10% !important;
            }
            .program {
                grid-template-columns: repeat(7, 14%) !important;
                .zi {
                    width: 90% !important;
                }
            }
            .main-ora {
                overflow: hidden;
                overflow-y: scroll;
            }
            .ore {
                display: flex;
                justify-content: center;
                
                padding: 0 5px;
                align-items: center;
                .butoane {
                    margin: 0;
                    align-items: center;
                    justify-content: space-evenly !important;
                    margin: 0 auto;
                }
            }
            .titlu-formular {
                .selectat {
                    font-size: 18px !important;

                }
                h1 {
                    font-size: 14px !important;
 

                }
            }

            .formular {
                .rand {
                    flex-direction: column;
                    .coloana {
                        width: 100% !important;
                    }
                    .an {
                        width: 100% !important;
                        .el-date-editor.el-input, .el-date-editor.el-input__inner {
                            width: 100% !important;
                            margin: 0;
                        }
                    }
                }
            }
        }

        .select-container {
            .serviciu {
                h1 {
                    font-size: 20px !important;
                }
                h4 {
                    font-size: 14px !important;
                }
            }

            .pret {
                h1 {
                    font-size: 18px !important;
                }
                h4 {
                    font-size: 12px !important;
                }
            }
        }


    }

    @keyframes fadeIn {
        0% { opacity: 0;}
        100% { opacity: 1;}
    }

    @keyframes FromTop {
        0% {
            margin-top: 20%;
        }

        100% {
            margin-top: 20px;
        }
    }

    @keyframes ToTopOrar {
        0% {
            margin-top: 20%;
        }

        100% {
            margin-top: 0;
        }
    }

    @keyframes grow {
        0% {
            font-size: 14px;
        }


        100% {
            font-size: 16px;
        }
    }
</style>